<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Imágenes MYT
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Revisa los resultados 🦴
        </b-card-title>
        <b-card-text class="mb-2">
          Ingresa el número de documento del paciente
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- documento -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="v-first-name">Documento</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="documento"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="documento del paciente"
                    v-model="documento"
                    :state="errors.length > 0 ? false:null"
                    name="documento"
                    placeholder="N. documento del paciente"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- danger -->
              <b-alert
                v-if="validar === 1"
                variant="danger"
                show
              >
                <div class="alert-body">
                  <span>El numero de documento es incorrecto</span>
                </div>
              </b-alert>
            </b-form-group>
            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validarPaciente(documento)"
            >
              Revisar
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'Home'}">
            <feather-icon icon="ChevronLeftIcon" /> Regresar
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            MYT
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup,
  BAlert,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      Tarjetaprofesional: '',
      documento: '',
      password: '',
      status: '',
      validar: '',
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    currentUser() {
      return this.$store.state.login.user
    },
  },
  methods: {
    validarPaciente(document) {
      this.usuarioDocumento = {
        documento: document,
        codigo: '',
        rol: this.currentUser.roles[0].name,
      }
      this.$store.dispatch('apiFast/validarUsuario', this.usuarioDocumento).then(response => {
        // console.log('datos correooooo')
        this.validar = response.data
        // console.log(response)
        // console.log(this.validar)
        if (this.validar) this.$router.push({ name: 'historial' })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
