<template>
  <!-- Coming soon page-->
  <div>
    <Medico />
  </div>

<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */

import store from '@/store/index'
import Medico from '@/assets/componentes/medico/Medico.vue'

export default {
  components: {
    Medico,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
